<script lang="ts">
    import { navigate } from "svelte-routing";
    import { toast } from "@zerodevx/svelte-toast";
    import { onMount, onDestroy } from "svelte";
    import { fetchGet, fetchPost } from "../services/fetch";
    import type { SvelteComponent } from "svelte";
    //import { newEvent } from "./services/stores";
    //import { updateRating } from "./services/Contact";
    //import { AppliedQuestionClientVm } from "./AppliedQuestionVm";
    import { StoreAppliedQuestionVm } from "../models/interfaces";
    //import AppliedImage from "./AppliedImage.svelte";
    import {
        AppliedExamListVm, //Get
        AppliedExamVm, //Store
    } from "../models/interfaces";

    let questionsVm: StoreAppliedQuestionVm[] = [];
    let filteredQuestionsVm: StoreAppliedQuestionVm[] = [];

    async function load() {
        const clientUrl = `/AppliedMath/GetProblems`;
        questionsVm = await fetchGet<StoreAppliedQuestionVm[]>(clientUrl);
    }

    let examListVm: AppliedExamListVm;
    let examList: AppliedExamVm[] = [];

    async function loadExams() {
        const clientUrlExam = `/AppliedMath/GetMyExams`;
        examListVm = await fetchGet<AppliedExamListVm>(clientUrlExam);
        if (examListVm.examList) {
            examList = examListVm.examList;
        }
    }

    onMount(() => {
        load();
        loadExams();
    });

    let showAll = false;

    $: heading = showAll ? "All tasks" : "Tasks created by me";

    let selectedExamId = 0; // Initially, no filter applied.

    $: {
        if (selectedExamId > 0) {
            let filteredQuestionsVm1 = questionsVm.filter((question) => question.examId === selectedExamId);
            filteredQuestionsVm = filteredQuestionsVm1.sort((a, b) => a.order - b.order); 
        } else {
            filteredQuestionsVm = questionsVm; // Show all
        }
    }
</script>

<div class="task-content">
    <div class="header-container">
        <p>
            <input type="checkbox" bind:checked={showAll} /> Show all applied math problems
        </p>
        <h2>{heading}</h2>
        <div class="filter">
            <label for="examFilter">Filter by Exam:</label>
            <select id="examFilter" bind:value={selectedExamId}>
                <option value="">None</option>
                {#each examList as exam (exam.id)}
                    <option value={exam.id}>{exam.title}</option>
                {/each}
            </select>
        </div>
    </div>
    <div class="edit-table">
        <div class="table">
            <div class="header cell">Username</div>
            <div class="header cell">Charid</div>
            <div class="header cell">Title</div>
            <div class="header cell">Exam</div>
            <div class="header cell">Created_At</div>
            <div class="header cell">Calculated_Rating</div>
            <div class="header cell">StepCount</div>
            <div class="header cell">Nominal Time</div>
            <div class="header cell">Times solved</div>
            <div class="header cell">No. of Correct</div>
            <div class="header cell">No. of Wrong</div>
            <div class="header cell">Sort Order</div>
            <div class="header cell">Suggested_Rating</div>
            <div></div>
            {#each filteredQuestionsVm as question}
                <div class="row">
                    <div class="cell">{question.user}</div>
                    <div class="cell"><a class="link" href="applied/{question.charid}">{question.charid}</a></div>
                    <div class="cell"><a class="link" href="applied/{question.charid}">{question.title}</a></div>
                    <div class="cell">{question.examTitle}</div>
                    <div class="cell">{question.created_date}</div>
                    <div class="cell">{question.calculated_rating}</div>
                    <div class="cell">{question.stepCount}</div>
                    <div class="cell">{question.suggested_time}</div>
                    <div class="cell">{question.solved_times}</div>
                    <div class="cell">{question.correct}</div>
                    <div class="cell">{question.wrong}</div>
                    <div class="cell">{question.order}</div>
                    <div class="cell">{question.suggested_rating}</div>
                    <button class="edit-button" on:click={() => navigate(`applied/${question.charid}/edit`)}>✏️Edit</button>
                </div>
            {/each}
        </div>
    </div>
</div>

<style>
    .table {
        display: grid;
        grid-template-columns: repeat(14, 1fr);
        gap: 5px;
        width: auto;
        height: auto;
    }
    @media (max-width: 300px) {
        .edit-table {
            width: auto;
            display: flex;
            overflow-x: auto;
            overflow-y: auto;
            width: 100%;
        }
        .task-content {
            width: 100%;
            height: 100%;
        }
        .cell {
            display: flex;
            border: none;
            justify-content: center;
            align-items: center;
        }
    }

    .cell {
        padding: 5px;
        border: 1px solid #ccc;
        padding: 5px;
    border: 1px solid #ccc;
    min-width: 150px; 
    overflow: hidden;
    text-overflow: ellipsis; 
    }
    .header {
        font-weight: bold;
    }
    .row {
        display: contents;
    }
    .row:hover {
    background-color: #f5f5f5;
}

    .link {
        color: white;
        text-decoration: underline;
    }
    .task-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }
    .header-container {
        display: flex;
        flex-direction: column;
    }
    .edit-table {
        display: flex;
        flex-direction: column;
        overflow-x: auto;
        width: 100%;
    }
    .filter {
        margin: 10px;
    }
    select,
    select:focus {
        width: 203px;
        height: 25px;
        border: 1px solid yellow;
        outline: none;
    }
    /* @media (max-width: 300px) {
        .edit-table {
            width: auto;
            display: flex;
            overflow-x: auto;
            width: 100%;
        }
    } */
    button {
        margin-top: 10px;
        width: 100px;
        color: white;
    }
</style>
