<script lang="ts">
    import { navigate } from "svelte-routing";
    import Result from "./Result.svelte";
    import { type ResultInterface } from "./ResultInterface";

    export let results: ResultInterface[]; //used for the results
    export let avgGoldTime;
    export let avgSilverTime;
    export let avgBronzeTime;

    function goHome() {
        console.log("Going home");
    }
    function goAgain() {
        location.reload();
    }
    let showResults = false;

    function toggleResultHighScore() {
        showResults = !showResults;
    }

    //let showResult = true;

    // function toggleResultHiscore() {
    //     showResults = !showResults;
    // }
</script>

<!-- <Result></Result> -->
<div class="go-container">
    <div class="gameover">
        <h1>Next time!</h1>
        <button on:click={toggleResultHighScore} id="result-button">Result</button>
    </div>
    <div class="scorepage-button">
        <button on:click={goAgain} class="button-scorepage"><img alt="redo" src="/restart.svg" /></button>
        <!-- <button on:click={() => navigate("/home")} class="button-scorepage">
            <img alt="himeicon" src="/homeicon.svg" style="" width="30px" height="30px" /></button
        > -->
    </div>

    {#if showResults}
        <Result {results} {avgGoldTime} {avgSilverTime} {avgBronzeTime}></Result>
    {:else}
        <!-- <HiScore {slug}></HiScore> -->
    {/if}
</div>

<style>
    .go-container {
        display: flex;
        flex-direction: column;
        text-align: center;
        position: absolute; /* Change this to fixed if you want it to stay in place during scroll */
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: rgba(0, 0, 0, 0.806);
        justify-content: center;
        align-items: center;
    }
    h1 {
        padding: 10px;
    }
    #result-button {
        font-size: 17px;
        width: 65px;
        height: 30px;
        color: white;
        margin-bottom: 10%;
    }
    .gameover {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 30px;
        margin: 10px;
        width: 430px;
        height: 320px;
        padding: 20px;
        text-shadow: 2px 2px 4px rgba(255, 255, 255, 0.3);
        background-color: #000;
        color: white;
        border: 5px solid yellow;
        padding-bottom: 20px;
        border-radius: 20px;
    }
    .scorepage-button {
        padding: 20px;
        display: inline-block;
    }
    img {
        height: 30px;
        margin-top: 5px;
    }
    @media (max-width: 600px) {
        .go-container {
            width: 80%;
            max-width: none;
        }

        .gameover {
            width: 100%;
        }
    }
</style>

