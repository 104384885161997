<script lang="ts">
    import { type ResultInterface } from "./ResultInterface";
    export let results: ResultInterface[];

    export let avgGoldTime;
    export let avgSilverTime;
    export let avgBronzeTime;
</script>

<div class="result-component">
    <div class="list">
        <div class="result item">Question</div>
        <div class="result item">Answer</div>
        <div class="result item">Your Answer</div>
        <div class="result item">Time Spent</div>
        <div class="result item">Points</div>
        {#each results as result}
            <div class="content-score item">{result.Question}</div>
            <div class="content-score item">{result.Answer}</div>
            <div class="content-score item" class:wrong={!result.IsCorrect}>{result.YourAnswer}</div>
            <div class="content-score item">{result.TimeSpent.toFixed(2)}</div>
            <div class="content-score item">{result.Points}</div>
        {/each}
    </div>
    <div class="per-task">
        Per Task:
        <div class="items">
            <div class="gld circle"></div>
            <div>&lt;{avgGoldTime}s</div>
        </div>
        <div class="items">
            <div class="slvr circle"></div>
            <div>&lt;{avgSilverTime}s</div>
        </div>
        <div class="items">
            <div class="brnz circle"></div>
            <div>&lt;{avgBronzeTime}s</div>
        </div>
    </div>
</div>

<style>
    
    .list {
        display: grid;
        grid-template-columns: auto auto auto auto auto;
        gap: 5px;
        position: relative;
        flex-direction: column;
        text-align: center;
        font-size: 20px;
        top: 0;
        margin-top: 20px;
        padding: 0 0 10px;
        color: white;
        background: rgb(0, 0, 0);
        width: auto;
        z-index: 8;
    }
    .content-score{
        padding: 5px;
        border: 1px solid rgba(255, 217, 0, 0.612);
    }
    .item {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
    }
    .result{
        background: gold;
        color: black;
    }
    .result-component{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        max-width: auto;
    }
    .wrong {
        background-color: rgba(255, 0, 0, 0.514);
    }
    .items {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
    } 
    .per-task {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 20px;
    }
    .circle {
        position: relative;
        width: 20px;
        height: 20px;
        border: 1px solid rgba(204, 194, 78, 0.418);
        border-radius: 50%;
        text-decoration: none;
        overflow: hidden;
        margin-left: 5px;
        box-shadow: 0 0 10px 3px rgba(255, 248, 168, 0.224);
        animation:
            pulse 4s infinite alternate,
            rotate 2s infinite;
    }
    .circle::before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
    }
    @keyframes pulse {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(1);
        }
    }
    @keyframes rotate {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(0deg);
        }
    }
    .gld::before {
        background: linear-gradient(#ffd782, #f3c334, #eabc32, #ffd782);
    }
    .slvr {
        border: 1px solid rgba(255, 255, 255, 0.247);
    }
    .slvr::before {
        background: linear-gradient(#ede8e8, #c2c2c2, #aeaeae, #9a9595, #e0dede);
    }
    .brnz {
        border: 1px solid rgba(255, 255, 255, 0.274);
    }
    .brnz::before {
        background: linear-gradient(#ffd8d8, #e8b094, #d67650, #da8f69, #ffd2d2);
    }
</style>
