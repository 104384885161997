<script lang="ts">
  import { onMount } from "svelte";
  import { loadStripe } from "@stripe/stripe-js"; // Import loadStripe as a function
  import type { Stripe } from "@stripe/stripe-js"; // Import Stripe as a type
  import { fetchGet } from "../services/fetch"; // Assuming your fetch functions are in this path
  import { navigate } from "svelte-routing";

  import { PollPaid } from "../services/stores";

  const onPaymentCompletion = () => {
    $PollPaid = true; // Start polling to check the payment status
  };

  let stripe: Stripe | null = null; // Use Stripe type
  let messages = "";

  // Use onMount to initialize Stripe
  onMount(async () => {
    // Fetch the publishable key
    const config = await fetchGet<{ publishableKey: string }>("/config");
    if (!config.publishableKey) {
      addMessage("No publishable key returned from the server.");
      return;
    }

    // Load the Stripe object asynchronously
    stripe = await loadStripe(config.publishableKey);

    if (!stripe) {
      addMessage("Stripe failed to load.");
      return;
    }

    // Retrieve the payment intent client secret from the URL
    const urlParams = new URLSearchParams(window.location.search);
    const clientSecret = urlParams.get("payment_intent_client_secret");

    if (clientSecret && stripe) {
      const { error, paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);
      if (error) {
        addMessage(error.message);
      } else {
        addMessage(`Payment ${paymentIntent.status}: ${paymentIntent.id}`);

        // Check if the payment was successful
        if (paymentIntent.status === "succeeded") {
          onPaymentCompletion();
        }
      }
    }
  });

  const addMessage = (message: string) => {
    messages = `> ${message}<br>`;
    console.log(`Debug: ${message}`);
  };
</script>

<main class="done">
  <h1>Thank you!</h1>
  <div class="container-chest">
    <!-- Smoke overlay -->
    <div class="smoke-container">
      <div class="smoke"></div>
      <div class="smoke"></div>
      <div class="smoke"></div>
      <div class="smoke"></div>
      <div class="smoke"></div>
      <div class="smoke"></div>
    </div>
    <!-- Image of the chest -->
    <img alt="open-chest" src="/open-chest.svg" style="height: 130px;" class="twinkle" />
  </div>
  <p>If the payment status doesn't update automatically after some seconds, click here <a href="/settings">Settings</a></p>
  <button on:click={() => navigate("/home")} class="bttn home-button">
    <img
      alt="home"
      src="/homeicon.svg"
      style="display: inline-block; vertical-align: middle; background-color:white; margin-bottom:2px;"
      width="20px"
      height="20px"
    />Home
  </button>

  {#if messages}
    <div id="messages" innerHTML={messages}></div>
  {/if}
</main>

<svelte:head>
  <script src="https://js.stripe.com/v3/"></script>
</svelte:head>

<style>
  p{
    font-size: .8rem;
  }
  a{
    text-decoration: underline;
  }
  .done {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .home-button {
    background: linear-gradient(to bottom, yellow, rgb(255, 183, 0));
    color: black;
    height: 30px;
    width: 100px;
    margin-top: 10px;
  }
  .home-button:hover {
    background: linear-gradient(to bottom, rgb(255, 183, 0), yellow);
  }
  .container-chest {
    position: relative;
    display: inline-block;
  }
  .smoke-container {
    position: absolute;
    top: 0;
    left: 30%;
    transform: translateX(-50%);
    width: 100px;
    height: 60px;
    pointer-events: none;
  }

  .smoke {
    position: absolute;
    bottom: 0;
    width: 20px;
    height: 20px;
    background-color: rgba(255, 255, 255, 0.811);
    border-radius: 50%;
    filter: blur(5px);
    animation: rise 5s infinite ease-in-out;
  }

  .smoke:nth-child(1) {
    left: 10px;
    animation-delay: 0s;
  }

  .smoke:nth-child(2) {
    left: 30px;
    animation-delay: 1s;
  }

  .smoke:nth-child(3) {
    left: 50px;
    animation-delay: 2s;
  }

  .smoke:nth-child(4) {
    left: 70px;
    animation-delay: 3s;
  }
  .smoke:nth-child(5) {
    left: 90px;
    animation-delay: 4s;
  }

  .smoke:nth-child(6) {
    left: 110px;
    animation-delay: 5s;
  }

  @keyframes rise {
    0% {
      transform: translateY(0) scale(0.5);
      opacity: 0.3;
    }
    50% {
      transform: translateY(-100px) scale(1.2);
      opacity: 0.5;
    }
    100% {
      transform: translateY(-200px) scale(1.5);
      opacity: 0;
    }
  }
</style>
