<script lang="ts">
	import { navigate } from "svelte-routing";
	import HiScore from "./HiScore.svelte";
	import { type ResultInterface } from "./ResultInterface";
	import Result from "./Result.svelte";

	export let results: ResultInterface[]; //used for the results
	export let slug: string; //only used for the hiscore
	//export let totalScore: number;
	export let progressBarWidth: number;
	export let taskCount: number;
	export let correctCount: number;
	export let wrongCount: number;
	export let timePoints: number;
	export let totalPoints: number;

	export let goldTime: number;
	export let silverTime: number;
	export let bronzeTime: number;

	export let avgGoldTime: number;
	export let avgSilverTime: number;
	export let avgBronzeTime: number;

	let showResults = false;

	function goAgain() {
		location.reload();
	}

	let resultHighscoreButtonText = "";

	$: resultHighscoreButtonText = showResults ? "Show highscore" : "Show individual results";

	function toggleResultHighScore() {
		showResults = !showResults;
	}
</script>

<div class="scorepage-container">
	<div class="heading">Your Score!</div>
	<div class="scorepage">
		<div class="content-score">
			<div class="total-score">Total Score: {totalPoints}</div>
			<div class="bar-gold">
				<div class="progress-container">
					<div class="progress-bar" style="width: {progressBarWidth}%"></div>
				</div>
			</div>
			<div class="text-container">
				<div class="text">Tasks: {taskCount}</div>
				<div class="text">Correct: {correctCount}</div>
				<div class="text">Wrong: {wrongCount}</div>
				<!--<div class="text">Milliseconds: {millisecondsSpent}</div>-->
				<div class="text">
					Official Time: {timePoints.toFixed(2)}
				</div>
			</div>
		</div>
		<div class="result">
			<div class="achieve">
				<div class="item">
					<div class="gld circle"></div>
					<div>&lt;{goldTime}s</div>
				</div>
				<div class="item">
					<div class="slvr circle"></div>
					<div>&lt;{silverTime}s</div>
				</div>
				<div class="item">
					<div class="brnz circle"></div>
					<div>&lt;{bronzeTime}s</div>
				</div>
			</div>

			<button on:click={toggleResultHighScore} class="result-button">{resultHighscoreButtonText}</button>
		</div>
	</div>

	{#if showResults}
		<Result {results} {avgGoldTime} {avgSilverTime} {avgBronzeTime}></Result>
	{:else}
		<HiScore {slug}></HiScore>
	{/if}

	<div class="buttons">
		<div class="scorepage-button">
			<button on:click={goAgain} id="button-scorepage"><img alt="redo" src="/restart.svg" width="30px" height="30px" /></button>
			<button on:click={() => navigate("/home")} id="button-scorepage">
				<img alt="home" src="/homeicon.svg" style="background-color:white" width="30px" height="30px" /></button
			>
		</div>
	</div>
</div>

<style>
	/* @import "../global.css"; */
	@media (max-width: 600px) {
		.heading {
			font-size: 18px;
		}
		.scorepage-container {
			width: auto;
			margin-top: 5em;
		}
		.text {
		font-size: 10px;
	}
	.text-container {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		gap: 5px;
		padding: 5px;
	}
	}
	.scorepage-button {
		display: flex;
	}
	.progress-container {
		display: flex;
		width: 300px;
		height: 10px;
		margin-top: 5px;
		background-color: #ddd;
		border-radius: 5px;
		overflow: hidden;
		border: 1px solid #fff700;
	}
	.bar-gold {
		display: flex;
	}

	.progress-bar {
		width: 70%;
		height: 10px;
		background-color: goldenrod;
		border-radius: 5px;
		transition: width 0.5s ease;
	}
	.heading {
		font-size: 50px;
		color: goldenrod;
		font-weight: bold;
		text-shadow: 2px 2px 4px rgb(141, 141, 141);
		/* text-stroke: 1px #ffffff; */
	}

	.text {
		display: flex;
		font-size: 20px;
	}
	.text-container {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		gap: 10px;
		padding: 10px;
		margin: 5px;
	}
	.total-score {
		margin-top: -5px;
		margin-bottom: 10px;
	}
	.result {
		display: flex;
		flex-direction: row;
		margin-top: 25px;
		gap: 60px;
		align-items: center;
		justify-content: space-between;
	}
	
	.achieve {
		position: absolute;
		font-size: 13px;
		margin-left: -6em;
	}
	.scorepage-container {
		display: flex;
		flex-direction: column;
		width: 100%;
		justify-content: center;
		align-items: center;
	}

	#button-scorepage {
		width: 120px;
		color: white;
		font-weight: bold;
	}
	.content-score {
		margin-top: 40px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.scorepage {
		display: flex;
		position: center;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		font-size: 30px;
		width: auto;
		height: 250px;
		text-shadow: 2px 2px 4px rgba(255, 255, 255, 0.3);
		background-color: #000;
		color: white;
		border: 5px solid goldenrod;
		padding-bottom: 20px;
		margin-bottom: 15px;
		border-radius: 10px;
		box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
	}
	.result-button {
		display: block;
		margin-left: 20px;
		margin-bottom: 10px;
		width: 150px;
		height: 30px;
		border: 2.5px solid rgb(255, 255, 0);
		background: none;
		color: white;
		font-size: 12px;
	}
	.result-button:hover {
		animation: glow-border 0.5s infinite alternate;
	}
	@keyframes glow-border {
		0% {
			border-color: gold;
			box-shadow: 0 0 10px gold;
		}
		100% {
			border-color: white;
			box-shadow: 0 0 20px gold;
		}
	}
	/* .container {
		justify-content: center;
		align-items: center;
		background: rgba(0, 0, 0, 0.806);
		z-index: 8;
	} */
	/* .menubutton {
		display: flex;
		position: fixed;
		width: 2000vw;
		height: 60px;
		top: 30px;
		left: -50px;
		right: 0;
		transform: translateY(-50%);
		background: black;
		border: 1px solid white;
		padding: 0;
		cursor: pointer;
		outline: none;
		z-index: 6;
	}
	.main-gray {
		background-color: rgb(120, 120, 120);
	}
	.gridItem {
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
} */

	.item {
		display: flex;
		align-items: center;
		margin-bottom: 5px;
	}
	.circle {
		display: inline-block;
		position: relative;
		width: 20px;
		height: 20px;
		border: 1px solid rgba(204, 194, 78, 0.418);
		border-radius: 50%;
		text-decoration: none;
		overflow: hidden;
		box-shadow: 0 0 10px 3px rgba(255, 248, 168, 0.224);
		animation:
			pulse 4s infinite alternate,
			rotate 2s infinite;
	}
	.circle::before {
		content: "";
		display: block;
		width: 100%;
		height: 100%;
	}
	@keyframes pulse {
		0% {
			transform: scale(1);
		}
		100% {
			transform: scale(1);
		}
	}
	@keyframes rotate {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(0deg);
		}
	}
	.gld::before {
		background: linear-gradient(#ffd782, #f3c334, #eabc32, #ffd782);
	}
	.slvr {
		border: 1px solid rgba(255, 255, 255, 0.247);
	}
	.slvr::before {
		background: linear-gradient(#ede8e8, #c2c2c2, #aeaeae, #9a9595, #e0dede);
	}
	.brnz {
		border: 1px solid rgba(255, 255, 255, 0.274);
	}
	.brnz::before {
		background: linear-gradient(#ffd8d8, #e8b094, #d67650, #da8f69, #ffd2d2);
	}
</style>
