<script lang="ts">
    import InfiniteButton from "./InfiniteButton.svelte";
    import { onMount } from "svelte";
    import { showDailyLimitAlert as showDailyLimitAlert } from "../services/stores";
    import { minutesUntilAuthorized } from "../services/stores";

    let formattedTime="";

    $: formattedTime = formatTime($minutesUntilAuthorized);

    // Function to format the minutes
    function formatTime(minutes: number): string {
        const roundedMinutes = Math.round(minutes); // Round to nearest integer
        const hours = Math.floor(roundedMinutes / 60); // Calculate full hours
        const remainingMinutes = roundedMinutes % 60; // Remaining minutes
        return `${hours} hours ${remainingMinutes} minutes`;
    }

    onMount(() => {
        const closeButton = document.querySelector<HTMLButtonElement>(".close-button");
        const overlay = document.querySelector<HTMLDivElement>(".premium");

        if (closeButton && overlay) {
            closeButton.addEventListener("click", () => {
                //overlay.style.display = "none";
                $showDailyLimitAlert = false;
            });
        }
    });
</script>

<div class="premium">
    <div class="premium-overlay">
        <div class="premium-body">
            <p class="header">Daily Limit</p>
            <h4>
                You're on fire! Don’t let daily limits slow you down. Keep your momentum going-unlock unlimited access now and take your
                math skills to the next level!
            </h4>
            <p>Continue the progress</p>
            <img alt="image3" src="/close-chest.svg" style="height: 130px;" />
            <InfiniteButton></InfiniteButton>
            <p class="timer">Or wait {formattedTime}</p>
        </div>
        <button data-test="close-button" class="close-button">
            <img src="https://d35aaqx5ub95lt.cloudfront.net/images/ed25a8cf69261b0c1e25b147f369f74a.svg" alt="Close button" />
            <span class="tooltip-text">Close</span>
        </button>
    </div>
</div>

<style>
    .premium {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    }
    .premium-overlay {
        display: flex;
        flex-direction: row;
        width: 370px;
    }
    .premium-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: black;
        border: 1px solid yellow;
        border-radius: 20px;
    }
    .header {
        color: white;
        font-size: 20px;
        font-weight: bold;
    }
    p {
        font-size: 10px;
        padding: 0;
        color: rgb(179, 179, 179);
    }
    button {
        background: black;
    }
    h4 {
        color: rgb(221, 220, 220);
        margin: 0;
        padding: 4px;
    }
    .close-button {
        cursor: pointer;
        z-index: 10;
        background: none;
        border: none;
        position: relative;
        width: 40px;
    }

    .tooltip-text {
        visibility: hidden;
        width: 60px;
        color: #fff;
        text-align: center;
        border-radius: 5px;
        padding: 5px;
        position: absolute;
        z-index: 1;
        font-size: 15px;
        top: 90%;
        left: 50%;
        margin-left: -35px;
        opacity: 0;
        transition: opacity 0.3s;
    }

    .close-button:hover .tooltip-text {
        visibility: visible;
        opacity: 1;
        display: block;
    }
    .timer {
        font-size: 12px;
        padding: 10px;
        color: #fff;
        text-shadow:
            0 0 5px rgb(43, 42, 37),
            0 0 10px rgb(70, 59, 2),
            0 0 15px rgba(255, 255, 0, 0.479),
            0 0 20px rgb(255, 243, 154);
    }
</style>
