<script lang="ts">
</script>
    <button class="unique-button">
        <a href="/payment" class="infinite-play">
            <span class="infinite-symbol">∞</span> Infinite Play
        </a>
    </button>

<style>
    /* @import "../global.css"; */
    .unique-button {
        border: solid 2px yellow;
        margin-top: 10px;
        width: 155px;
    }

    .infinite-play {
        color: yellow;
        display: flex;
        align-items: center;
        text-decoration: none;
    }
    .infinite-symbol {
        font-size: 2.5em;
        margin-right: 10px;
        margin-bottom: 10px;
    }
</style>
