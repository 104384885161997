<script lang="ts">
    import { fetchGet, fetchPost } from "./services/fetch";
    import { EmailVm, Contact } from "./models/interfaces";
    import { onMount, onDestroy } from "svelte";
    import { isAuthenticated } from "./services/stores";

    let email = "";
    let name = "";
    let subject = "";
    let message = "";
    let confirmationMessage = "";

    onMount(() => {
        loadUserData();

        const unsubscribe = isAuthenticated.subscribe((auth) => {
            if (!auth) {
                window.location.href = "/login";
            }
        });

        onDestroy(unsubscribe);
    });

    async function loadUserData() {
        let url = "User/GetUser";

        let contact = await fetchGet<Contact>(url);
        if (contact.name != undefined && contact.email != undefined) {
            name = contact.name;
            email = contact.email;
        }
    }

    async function sendMessage() {
        if (name.trim() && subject.trim() && message.trim()) {
            let url = "Email/SendEmail";

            //public async Task<IActionResult> SendEmail([FromBody] EmailVm emailVm)
            let emailVm = new EmailVm();
            emailVm.fromEmailAddress = email;
            emailVm.fromName = name;
            emailVm.message = message;
            emailVm.subject = subject;

            let result = await fetchPost<boolean, EmailVm>(url, emailVm);

            confirmationMessage = "Your message has been sent!";
            name = "";
            subject = "";
            message = "";
        } else {
            confirmationMessage = "Please fill out all fields.";
        }
    }

    function autoResize(event: Event) {
        const textarea = event.target as HTMLTextAreaElement;
        textarea.style.height = "auto";
        textarea.style.height = `${textarea.scrollHeight}px`;
    }
</script>

<div class="container">
    <div class="email-component">
        <label for="email">From:</label>
        <input id="email" type="email" bind:value={email} readonly />

        <label for="name">Name:</label>
        <input id="name" type="text" bind:value={name} placeholder="Enter your name" />

        <label for="subject">Subject:</label>
        <input id="subject" type="text" bind:value={subject} placeholder="Enter the subject" />

        <label for="message">Message:</label>
        <div class="message-content">
            <textarea id="message" bind:value={message} placeholder="Enter your message" on:input={autoResize} style="overflow: hidden"
            ></textarea>

            <button id="send-btn" class="unique-button" on:click={sendMessage}>
                <img class="send-icon" src="paperplane.png" alt="Send Icon" width="20" />
                <span>Send</span>
            </button>
            {#if confirmationMessage}
                <div class="confirmation">{confirmationMessage}</div>
            {/if}
        </div>
    </div>
    <p>For support, contact us at: <a href="mailto:support@plezuz.com" class="support-email">support@plezuz.com</a></p>
</div>

<style>
    /* @import "./global.css"; */
    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100vh;
    }
    .message-content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    #message {
        width: 500px;
        max-width: 400px;
        margin-left: 4%;
    }
    .email-component {
        text-align: left;
        padding: 20px;
        border-radius: 8px;
        width: 500px;
        max-width: 400px;
    }
    #send-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        background: none;
        color: white;
        padding: 10px 20px;
        border-radius: 5px;
        width: 100px;
        cursor: pointer;
        font-size: 16px;
    }

    .send-icon {
        margin-right: 5px;
    }

    .confirmation {
        margin-top: 10px;
        color: green;
    }

    input,
    textarea {
        display: block;
        width: auto;
        margin: 10px 0;
        padding: 8px;
        border-radius: 5px;
        border: 1px solid #ccc;
    }

    label {
        font-weight: bold;
    }
    .email-component label,
    .email-component input,
    .email-component textarea,
    .email-component button {
        width: 100%;
        margin-bottom: 10px;
    }
    .support-email {
        text-decoration: underline;
    }
    @media (max-width: 500px) {
        .email-component {
            padding: 15px;
            width: 300px;
        }
        #message {
            width: 300px;
        }
        .message-content button {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 8px 12px;
        }

        .send-icon {
            margin-right: 5px;
        }
    }
</style>
