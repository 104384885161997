<script lang="ts">
    import { fetchGet } from "../services/fetch";
    import { type HighscoreVM, type ScoreVm } from "../ScoreInterface";

    export let slug: string;

    let goldTime = 0.0;
    let silverTime = 0.0;
    let bronzeTime = 0.0;

    let displayedScores: ScoreVm[] = [];
    let currentScore: ScoreVm = { id: 0, rank: 0, time: 0, date: "", points: 0 }; // Initialize with default values

    $: console.log("GoldTime: " + goldTime);

    function formatDate(dateString: string): string {
        const date = new Date(dateString);

        // Formatting the date in a 'YYYY-MM-DD HH:MM:SS' format
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() returns 0-11
        const day = String(date.getDate()).padStart(2, "0");
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const seconds = String(date.getSeconds()).padStart(2, "0");

        if (year == 1970) {
            return "0";
        }

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    // Usage
    const backendDateString = "2024-01-19T06:59:26.075409+00:00";
    const formattedDate = formatDate(backendDateString);
    console.log(formattedDate); // Output: 2024-01-19 06:59:26

    async function getHiScores() {
        var clientUrl = `/FastMentalMath/GetHiscores/${slug}`;
        var hiScoreVm = await fetchGet<HighscoreVM>(clientUrl);
        currentScore.id = hiScoreVm.currentScoreId;
        goldTime = hiScoreVm.goldTime;
        silverTime = hiScoreVm.silverTime;
        bronzeTime = hiScoreVm.bronzeTime;
        displayedScores = hiScoreVm.scoreVms.map((score) => ({
            id: score.id,
            rank: score.rank,
            //value: score.value,
            time: score.time < 100000000 ? score.time : 0,
            date: formatDate(score.date),
            points: score.points,
        }));
    }

    getHiScores();
</script>

<!-- 
<div class="highscore-list">
    {#each displayedScores as score}
        <div class={score.id === currentScore.id ? "current-score" : ""}>
            Rank: {score.rank} Time {score.time} Date {score.date}
        </div>
    {/each}
</div> -->

{#if displayedScores && displayedScores.length > 1}
    <div class="highscore-task">
        <div class="highscore-list">
            <!-- First Row -->
            <div class="score item a">Rank</div>
            <div class="score item b">Time</div>
            <div class="score item c">Date</div>
            <div class="score item d">Achieved</div>
            <div class="score item d">Points</div>
            {#each displayedScores as score}
                {#if score.id == currentScore.id}
                    <div class="current-score item e">{score.rank}</div>
                    <div class="current-score item f">{score.time}</div>
                    <div class="current-score item g">{score.date}</div>
                    <div class="current-score item h">
                        {#if score.time && score.time < goldTime}
                            <div class="gld circle"></div>
                        {:else if score.time && score.time < silverTime}
                            <div class="slvr circle"></div>
                        {:else if score.time && score.time < bronzeTime}
                            <div class="brnz circle"></div>
                        {/if}
                    </div>
                    <div class="current-score item i">{score.points}</div>
                {:else}
                    <div class="item e">{score.rank}</div>
                    <div class="item f">{score.time}</div>
                    <div class="item g">{score.date}</div>
                    <div class="item h">
                        {#if score.time && score.time < goldTime}
                            <div class="gld circle"></div>
                        {:else if score.time && score.time < silverTime}
                            <div class="slvr circle"></div>
                        {:else if score.time && score.time < bronzeTime}
                            <div class="brnz circle"></div>
                        {/if}
                    </div>
                    <div class="item h">{score.points}</div>
                {/if}
            {/each}
        </div>
        <div class="per-task">
            <div class="items">
                <div class="gld circle"></div>
                <div>&lt;{goldTime}s</div>
            </div>
            <div class="items">
                <div class="slvr circle"></div>
                <div>&lt;{silverTime}s</div>
            </div>
            <div class="items">
                <div class="brnz circle"></div>
                <div>&lt;{bronzeTime}s</div>
            </div>
        </div>
    </div>
{/if}

<!-- <canvas id="scoreChart"></canvas> -->
<style>
    @media (max-width: 600px) {
        .highscore-task {
            display: flex;
            justify-content: center;
            align-items: center;
            width: auto;
            margin: 0;
            padding: 0;
        }
        .highscore-list{
            margin: 5px;
        }
    }
    .highscore-task {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: auto;
    }
    .highscore-list {
        display: grid;
        grid-template-columns: auto auto auto auto auto;
        gap: 5px;
        position: relative;
        flex-direction: column;
        text-align: center;
        font-size: 20px;
        top: 0;
        margin-top: 5em;
        padding: 0 0 10px;
        color: white;
        background: rgb(0, 0, 0);
        width: auto;
        z-index: 8;
    }

    .current-score {
        background: #f0e68ce4;
        color: black;
    }
    .score {
        background: gold;
        color: black;
    }
    .item {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
        border: 1px solid rgba(255, 217, 0, 0.612);
    }
    .circle {
        position: relative;
        width: 20px;
        height: 20px;
        border: 1px solid rgba(204, 194, 78, 0.418);
        border-radius: 50%;
        text-decoration: none;
        overflow: hidden;
        margin-left: 5px;
        box-shadow: 0 0 10px 3px rgba(255, 248, 168, 0.224);
        animation:
            pulse 4s infinite alternate,
            rotate 2s infinite;
    }
    .circle::before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
    }
    @keyframes pulse {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(1);
        }
    }
    @keyframes rotate {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(0deg);
        }
    }
    .gld::before {
        background: linear-gradient(#ffd782, #f3c334, #eabc32, #ffd782);
    }
    .slvr {
        border: 1px solid rgba(255, 255, 255, 0.247);
    }
    .slvr::before {
        background: linear-gradient(#ede8e8, #c2c2c2, #aeaeae, #9a9595, #e0dede);
    }
    .brnz {
        border: 1px solid rgba(255, 255, 255, 0.274);
    }
    .brnz::before {
        background: linear-gradient(#ffd8d8, #e8b094, #d67650, #da8f69, #ffd2d2);
    }
    .per-task {
        display: flex;
        justify-content: center;
        flex-direction: row;
        gap: 20px;
    }
    .items {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
    }
</style>
