<script lang="ts">
    import "./appliedmath.css";
    import { onMount } from "svelte";
    import { fetchGet, fetchPost } from "../services/fetch";
    // import { AppliedQuestionServerVm } from "../models/interfaces";

    import {
        AppliedExamListVm, //Get
        AppliedExamVm, //Store
    } from "../models/interfaces";

    import { toast } from "@zerodevx/svelte-toast";

    let examListVm: AppliedExamListVm;
    let examList: AppliedExamVm[] = [];

    async function load() {
        const clientUrlExam = `/AppliedMath/GetMyExams`;
        examListVm = await fetchGet<AppliedExamListVm>(clientUrlExam);
        if (examListVm.examList) {
            examList = examListVm.examList;
        }
    }

    let examName = "";
    let rating = 0;

    onMount(() => {
        load();
    });

    let showAll = false;

    //$: heading = showAll ? "All tasks" : "Tasks created by me";

    async function createExam() {
        var url = "AppliedMath/StoreAppliedExam";
        let appliedExamVm = new AppliedExamVm();
        appliedExamVm.title = examName;
        appliedExamVm.rating = rating;
        //var charIdObject =
        await fetchPost<any, AppliedExamVm>(url, appliedExamVm);

        // toast.push(`Save ok, CharId: ${charIdObject.charId}`, {
        //     classes: ["success"],
        //     pausable: true,
        // });

        toast.push(`Save ok`, {
            classes: ["success"],
            pausable: true,
        });

        await load();
    }

    async function deleteExamAsync(id: number) {
        var url = "AppliedMath/DeleteAppliedExam";
        let appliedExamVm = new AppliedExamVm();
        appliedExamVm.title = examName;
        appliedExamVm.id = id;
        //var charIdObject =
        await fetchPost<any, AppliedExamVm>(url, appliedExamVm);

        // toast.push(`Save ok, CharId: ${charIdObject.charId}`, {
        //     classes: ["success"],
        //     pausable: true,
        // });

        toast.push(`Delete ok`, {
            classes: ["success"],
            pausable: true,
        });

        await load();
    }
</script>

<div class="examlist">
    <div class="exam-input">
        <div class="exam-name">
            <input type="text" id="input" placeholder="Exam name" bind:value={examName} />
            <input type="number" placeholder="General Rating" id="input" name="quantity" min="1" max="3000" bind:value={rating} />
        </div>
        <button on:click={createExam} class="create-button">Create Exam</button>
    </div>
    <div class="table">
        <div class="header cell">Username</div>
        <div class="header cell">charid</div>
        <div class="header cell">Title</div>
        <div class="header cell">Created at</div>
        <div class="header cell">Rating</div>
        <div></div>
        {#each examList as exam}
            <div class="row">
                <div class="cell">{exam.user}</div>
                <div class="cell"><a class="link" href="applied/{exam.charid}">{exam.charid}</a></div>
                <div class="cell"><a class="link" href="applied/{exam.charid}">{exam.title}</a></div>
                <div class="cell">{exam.created_date}</div>
                <div class="cell">{exam.rating}</div>
            </div>
            <button id="delete-icon-button" on:click={() => deleteExamAsync(exam.id)}>
                <img class="delete-icon" src="delete-24.png" alt="delete Icon" width="20" />
            </button>
        {/each}
    </div>
</div>

<style>
    .examlist {
        width: 100%;
    }
    input {
        width: 200px;
        border: 1px solid yellow;
        outline: none;
    }
    input:focus {
        border-color: yellow;
    }
    .table {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        gap: 5px;
        width: auto;
        height: auto;
    }
    .cell {
        padding: 5px;
        border: 1px solid #ccc;
    }
    .header {
        font-weight: bold;
    }
    .row {
        display: contents;
    }
    .link {
        color: white;
        text-decoration: underline;
    }
    .exam-name {
        display: flex;
        flex-direction: row;
        gap: 5px;
    }
    .exam-input {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 10px;
        justify-content: center;
        align-items: center;
    }
    .create-button {
        margin: 20px;
    }
    .delete-icon {
        margin: 0 10px 0 10px;
        cursor: pointer;
    }
    #delete-icon-button {
        width: 20px;
        height: 25px;
        margin: 15px 0 0 10px;
        border: none;
    }
</style>
